/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

import { useEffect, useRef, useCallback, useState } from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { getInstructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import { X, Edit, Zap, ArrowUp, ArrowDown } from 'react-feather';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';

import './ConsolePage.scss';
import { isJsxOpeningLikeElement } from 'typescript';

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

// Add these new interfaces
interface WeatherResponse {
  current: {
    temperature_2m: number;
    wind_speed_10m: number;
  };
  current_units: {
    temperature_2m: string;
    wind_speed_10m: string;
  };
}

interface AvailableSlots {
  availableSlots: string[];
  rawSlots: string[];
  message: string;
}

interface BookMeetingResponse {
  status: string;
  meetingUrl?: string;
  start?: string;
  end?: string;
  duration?: number;
  attendeeName?: string;
  attendeeEmail?: string;
}

interface NotificationResponse {
  success: boolean;
  message: string;
}

// Add this before the ConsolePage component
const TOOLS_CONFIG = {
  GET_WEATHER: {
    name: 'get_weather',
    description: 'Retrieves the weather for a given lat, lng coordinate pair. Specify a label for the location.',
    parameters: {
      type: 'object',
      properties: {
        lat: { type: 'number', description: 'Latitude' },
        lng: { type: 'number', description: 'Longitude' },
        location: { type: 'string', description: 'Name of the location' }
      },
      required: ['lat', 'lng', 'location']
    }
  },
  GET_SLOTS: {
    name: 'get_available_slots',
    description: 'Récupère les créneaux de réunion disponibles sur les 10 prochains jours (maximum 2 créneaux par jour).',
    parameters: {
      type: 'object',
      properties: {},
      required: []
    }
  },
  BOOK_MEETING: {
    name: 'book_meeting',
    description: 'Books a meeting for a specific time slot using the Cal.com API.',
    parameters: {
      type: 'object',
      properties: {
        startTime: { type: 'string', description: 'Start time of the meeting in ISO format' },
        attendee: {
          type: 'object',
          properties: {
            name: { type: 'string', description: 'Name of the attendee.' },
            email: { type: 'string', description: 'Email of the attendee.' }
          },
          required: ['name', 'email']
        },
        notes: { type: 'string', description: 'Additional notes about the meeting topic.' }
      },
      required: ['startTime', 'attendee', 'notes']
    }
  },
  SEND_NOTIFICATION: {
    name: 'send_notification',
    description: 'Envoie une notification push via Pushover à Arthur',
    parameters: {
      type: 'object',
      properties: {
        title: { type: 'string', description: 'Titre de la notification' },
        message: { type: 'string', description: 'Contenu de la notification' }
      },
      required: ['title', 'message']
    }
  }
};

// Add these tool implementation functions
const toolImplementations = {
  async getWeather({ lat, lng }: { lat: number; lng: number }): Promise<WeatherResponse> {
    try {
      const response = await fetch(
        `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current=temperature_2m,wind_speed_10m`
      );
      if (!response.ok) throw new Error('Weather API request failed');
      return await response.json();
    } catch (error) {
      console.error('Weather fetch error:', error);
      throw error;
    }
  },

  async getAvailableSlots(): Promise<AvailableSlots> {
    // Get current time in Paris
    const parisTime = new Date().toLocaleString('en-US', { timeZone: PARIS_TIMEZONE });
    const startTime = new Date(parisTime);
    startTime.setMinutes(0, 0, 0);
    startTime.setHours(startTime.getHours() + 1);

    const endTime = new Date(startTime);
    endTime.setDate(endTime.getDate() + 10);
    
    try {
      const response = await fetch(
        `https://api.cal.com/v2/slots/available?duration=30&startTime=${encodeURIComponent(startTime.toISOString())}&endTime=${encodeURIComponent(endTime.toISOString())}&eventTypeId=845390`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CAL_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch slots');
      const data = await response.json();
      
      if (data.status !== 'success' || !data.data?.slots) {
        throw new Error('Invalid response format from Cal.com API');
      }

      // Format slots by day with French date formatting
      const slotsByDay: { [key: string]: { formatted: string; raw: string }[] } = {};
      const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
        timeZone: PARIS_TIMEZONE,
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit'
      });

      // Get current time plus 2 hours for minimum booking time
      const minBookingTime = new Date(parisTime);
      minBookingTime.setHours(minBookingTime.getHours() + 2);

      (Object.entries(data.data.slots) as [string, Array<{ time: string }>][])
        .forEach(([date, slots]) => {
          slots.forEach((slot: { time: string }) => {
            const slotDate = new Date(slot.time);
            
            // Skip if slot is less than 2 hours from now (using Paris time)
            if (slotDate.getTime() <= minBookingTime.getTime()) {
              return;
            }

            const dayKey = slotDate.toISOString().split('T')[0];
            const formattedTime = dateFormatter.format(slotDate);

            if (!slotsByDay[dayKey]) {
              slotsByDay[dayKey] = [];
            }

            // Only keep first two slots per day
            if (slotsByDay[dayKey].length < 2) {
              slotsByDay[dayKey].push({
                formatted: formattedTime,
                raw: slot.time
              });
            }
          });
        });

      // Créer deux tableaux parallèles : un pour l'affichage et un pour les données brutes
      const formattedSlots: string[] = [];
      const rawSlots: string[] = [];

      Object.entries(slotsByDay).forEach(([day, slots]) => {
        slots.forEach(slot => {
          formattedSlots.push(slot.formatted);
          rawSlots.push(slot.raw);
        });
      });

      // Trier les deux tableaux en parallèle
      const sortedIndices = formattedSlots
        .map((_, index) => index)
        .sort((a, b) => new Date(rawSlots[a]).getTime() - new Date(rawSlots[b]).getTime());

      const sortedFormatted = sortedIndices.map(i => formattedSlots[i]);
      const sortedRaw = sortedIndices.map(i => rawSlots[i]);

      const numberOfDays = Object.keys(slotsByDay).length;

      let message: string;
      if (sortedFormatted.length === 0) {
        message = "Je n'ai trouvé aucun créneau disponible pour les 10 prochains jours.";
      } else {
        message = `J'ai trouvé ${sortedFormatted.length} créneaux disponibles sur ${numberOfDays} jour${numberOfDays > 1 ? 's' : ''} :\n\n`;
        Object.entries(slotsByDay).forEach(([day, slots]) => {
          const dayDate = new Date(day);
          const dayFormatter = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'long',
            day: 'numeric',
            month: 'long'
          });
          message += `${dayFormatter.format(dayDate)} :\n`;
          slots.forEach(slot => {
            message += `- ${slot.formatted}\n`;
          });
          message += '\n';
        });
      }

      return { 
        availableSlots: sortedFormatted,
        rawSlots: sortedRaw,
        message
      };
    } catch (error) {
      console.error('Error fetching slots:', error);
      throw new Error('Désolé, je n\'arrive pas à récupérer les créneaux disponibles pour le moment. Veuillez réessayer plus tard.');
    }
  },

  async bookMeeting(params: { startTime: string; attendee: { name: string; email: string }; notes: string }): Promise<BookMeetingResponse> {
    try {
      // Convert the startTime to Paris timezone for verification
      const slotTime = new Date(params.startTime);
      const parisTime = new Date().toLocaleString('en-US', { timeZone: PARIS_TIMEZONE });
      const minBookingTime = new Date(parisTime);
      minBookingTime.setHours(minBookingTime.getHours() + 2);

      // Verify the slot is at least 2 hours in the future
      if (slotTime.getTime() <= minBookingTime.getTime()) {
        throw new Error('Ce créneau n\'est plus disponible car trop proche');
      }

      const response = await fetch('https://api.cal.com/v2/bookings', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_CAL_API_KEY}`,
          'Content-Type': 'application/json',
          'cal-api-version': '2024-08-13'
        },
        body: JSON.stringify({
          start: params.startTime,
          lengthInMinutes: 30,
          eventTypeId: parseInt(process.env.REACT_APP_CAL_EVENT_TYPE_ID || '845390', 10),
          attendee: {
            ...params.attendee,
            timeZone: PARIS_TIMEZONE,
            language: 'fr'
          },
          bookingFieldsResponses: {
            notes: params.notes
          }
        })
      });

      const data = await response.json();
      
      if (data.status !== 'success') {
        throw new Error(data.message || 'La réservation a échoué');
      }
      
      return {
        status: data.status,
        meetingUrl: data.data.meetingUrl,
        start: data.data.start,
        end: data.data.end,
        duration: data.data.duration,
        attendeeName: data.data.attendees[0]?.name,
        attendeeEmail: data.data.attendees[0]?.email,
      };
    } catch (error) {
      console.error('Booking error:', error);
      throw error;
    }
  },

  async sendNotification({ title, message }: { title: string; message: string }): Promise<NotificationResponse> {
    const token = process.env.REACT_APP_PUSHOVER_TOKEN;
    const user = process.env.REACT_APP_PUSHOVER_USER;

    if (!token || !user) {
      return { success: false, message: 'Configuration Pushover manquante' };
    }

    try {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('user', user);
      formData.append('title', title);
      formData.append('message', message);

      const response = await fetch('https://api.pushover.net/1/messages.json', {
        method: 'POST',
        body: formData
      });
      
      const data = await response.json();
      return {
        success: data.status === 1,
        message: data.status === 1 ? 'Notification envoyée' : 'Échec de l\'envoi'
      };
    } catch (error) {
      console.error('Notification error:', error);
      throw error;
    }
  }
};

// Add timezone utilities at the top
const PARIS_TIMEZONE = 'Europe/Paris';

// Ajouter cette fonction utilitaire
const getCurrentParisTime = () => {
  const formatter = new Intl.DateTimeFormat('fr-FR', {
    timeZone: PARIS_TIMEZONE,
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
  return formatter.format(new Date());
};

export function ConsolePage() {
  /**
   * Ask user for API Key
   * If we're using the local relay server, we don't need this
   */
  const apiKey = LOCAL_RELAY_SERVER_URL
    ? ''
    : localStorage.getItem('tmp::voice_api_key') ||
      prompt('OpenAI API Key') ||
      '';
  if (apiKey !== '') {
    localStorage.setItem('tmp::voice_api_key', apiKey);
  }

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : {
            apiKey: apiKey,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [showConversations, setShowConversations] = useState(false);
  const [circleScale, setCircleScale] = useState(1);
  const currentScaleRef = useRef(1);

  // Add new state and ref for timeout
  const [lastActivityTime, setLastActivityTime] = useState<number>(Date.now());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * When you click the API key
   */
  const resetAPIKey = useCallback(() => {
    const newApiKey = prompt('OpenAI API Key');
    if (newApiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', newApiKey);
      window.location.reload();
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setItems([]);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  // Add function to handle activity
  const updateActivity = useCallback(() => {
    setLastActivityTime(Date.now());
  }, []);

  // Add function to check timeout
  const checkTimeout = useCallback(() => {
    if (!isConnected) return;

    const currentTime = Date.now();
    const timeSinceLastActivity = currentTime - lastActivityTime;

    if (timeSinceLastActivity > 120000) { // 2 minutes (120,000 ms)
      console.log('Session timed out after 2 minutes of inactivity');
      const client = clientRef.current;
      client.disconnect(); // Explicitly disconnect the client
      disconnectConversation();
    }
  }, [isConnected, lastActivityTime, disconnectConversation]);

  /**
   * Connect to conversation
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `Hello!`,
      },
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }

    updateActivity();
  }, [updateActivity]);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async (e?: React.MouseEvent | React.TouchEvent) => {
    // Prevent default only for touch events to avoid scrolling
    if (e?.type?.startsWith('touch')) {
      e.preventDefault();
    }
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));

    updateActivity();
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async (e?: React.MouseEvent | React.TouchEvent) => {
    if (e?.type?.startsWith('touch')) {
      e.preventDefault();
    }
    const wavRecorder = wavRecorderRef.current;
    
    if (wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
      const client = clientRef.current;
      client.createResponse();
    }
    
    setIsRecording(false);
    updateActivity();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
  };

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    const render = () => {
      if (isLoaded) {
        // Get input amplitude (user speaking)
        const inputResult = wavRecorder?.recording && wavRecorder.getStatus() === 'recording'
          ? wavRecorder.getFrequencies('voice')
          : { values: new Float32Array([0]) };

        // Get output amplitude (assistant speaking)
        const outputResult = wavStreamPlayer?.analyser
          ? wavStreamPlayer.getFrequencies('voice')
          : { values: new Float32Array([0]) };

        // Calculate the average amplitude from both input and output
        const inputAmplitude = 
          inputResult.values.reduce((sum, value) => sum + value, 0) / inputResult.values.length;
        const outputAmplitude = 
          outputResult.values.reduce((sum, value) => sum + value, 0) / outputResult.values.length;

        // Use the larger of the two amplitudes
        const amplitude = Math.max(inputAmplitude, outputAmplitude);
        
        // Calculate target scale
        const targetScale = 1 + Math.min(amplitude * 2, 0.5);
        
        // Smooth interpolation between current and target scale
        const smoothingFactor = 0.15; // Adjust this value to control smoothing (0-1)
        currentScaleRef.current += (targetScale - currentScaleRef.current) * smoothingFactor;
        
        // Update the circle scale
        setCircleScale(currentScaleRef.current);

        requestAnimationFrame(render);
      }
    };

    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    const client = clientRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    
    // Set up basic configuration with current Paris time
    client.updateSession({ 
      instructions: getInstructions(getCurrentParisTime()),
      voice: 'alloy',
      input_audio_transcription: { model: 'whisper-1' }
    });

    // Mettre à jour l'heure toutes les minutes
    const timeUpdateInterval = setInterval(() => {
      client.updateSession({ 
        instructions: getInstructions(getCurrentParisTime())
      });
    }, 60000); // Mise à jour toutes les minutes

    // Add tools with improved configuration
    client.addTool(TOOLS_CONFIG.GET_WEATHER, toolImplementations.getWeather);
    client.addTool(TOOLS_CONFIG.GET_SLOTS, toolImplementations.getAvailableSlots);
    client.addTool(TOOLS_CONFIG.BOOK_MEETING, toolImplementations.bookMeeting);
    client.addTool(TOOLS_CONFIG.SEND_NOTIFICATION, toolImplementations.sendNotification);

    // handle realtime events from client + server for event logging
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      try {
        const items = client.conversation.getItems();
        if (delta?.audio && items.find(i => i.id === item.id)) {
          await wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        const cleanedItems = items.map(item => {
          if (item.formatted?.file) {
            const { file, ...rest } = item.formatted;
            return { ...item, formatted: rest };
          }
          return item;
        });
        setItems(cleanedItems);
      } catch (error) {
        console.warn('Error processing conversation update:', error);
      }
    });

    setItems(client.conversation.getItems());

    return () => {
      clearInterval(timeUpdateInterval);
      client.reset();
    };
  }, []);

  // Add useEffect for timeout checking
  useEffect(() => {
    if (isConnected) {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }

      // Set up new timeout check interval
      timeoutRef.current = setInterval(checkTimeout, 1000);

      // Update activity when receiving messages or audio
      const client = clientRef.current;
      const handleActivity = () => {
        updateActivity();
      };

      // Add event listeners for all relevant activities
      client.on('conversation.updated', handleActivity);
      client.on('conversation.created', handleActivity);
      client.on('audio.received', handleActivity);
      client.on('audio.sent', handleActivity);

      return () => {
        if (timeoutRef.current) {
          clearInterval(timeoutRef.current);
          timeoutRef.current = null;
        }
        // Remove all event listeners
        client.off('conversation.updated', handleActivity);
        client.off('conversation.created', handleActivity);
        client.off('audio.received', handleActivity);
        client.off('audio.sent', handleActivity);
      };
    }
  }, [isConnected, checkTimeout, updateActivity]);

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <div className="content-top">
        <div className="content-title">
          <img src="/blue.svg" />
          <span>made in paris</span>
        </div>
        <div className="content-api-key">
          {!LOCAL_RELAY_SERVER_URL && (
            <Button
              icon={Edit}
              iconPosition="end"
              buttonStyle="flush"
              label={`api key: ${apiKey.slice(0, 3)}...`}
              onClick={() => resetAPIKey()}
            />
          )}
        </div>
      </div>
      <div className="content-main">
        <div className="content-logs">
          {/* Remove content-actions from here */}
        </div>
      </div>
      {/* Add content-actions here at root level */}
      <div className="content-actions">
        {isConnected && canPushToTalk && (
          <Button
            className="push-to-talk"
            label={isRecording ? 'release to send' : 'push to talk'}
            buttonStyle={isRecording ? 'alert' : 'regular'}
            disabled={!isConnected || !canPushToTalk}
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onTouchStart={startRecording}
            onTouchEnd={stopRecording}
          />
        )}
        <div className="bottom-row">
          <Toggle
            defaultValue={false}
            labels={['push', 'live']}
            values={['none', 'server_vad']}
            onChange={(_, value) => changeTurnEndType(value)}
          />
          <div className="spacer" />
          <Button
            label={isConnected ? 'disconnect' : 'connect'}
            iconPosition={isConnected ? 'end' : 'start'}
            icon={isConnected ? X : Zap}
            buttonStyle={isConnected ? 'regular' : 'action'}
            onClick={isConnected ? disconnectConversation : connectConversation}
          />
        </div>
      </div>
      <div
        className="center-circle"
        style={{ transform: `translate(-50%, -50%) scale(${circleScale})` }}
      ></div>
    </div>
  );
}
